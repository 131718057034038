import React from "react"
import PropTypes from "prop-types"

import MainNavBar from "./mainNavBar"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="topRightPhone">1-800-358-4268</div>
    <MainNavBar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
